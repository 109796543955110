<template>
    <div class="item-top-area d-flex align-items-center">
        <div class="item-right ml-auto">
            <div class="switch-toggle">
                <span>Show Title</span>
                <label class="switch" for="show_title">
                    <input v-model="getBioLinkContentSectionAdd.show_title" type="checkbox"
                           id="show_title">
                    <div class="slider round"></div>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default ({
  methods: {},
  computed: {
    ...mapGetters(['getBioLinkContentSectionAdd'])
  }
})
</script>
