<template>
  <div class="bio_link_main_view">

    <div class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">RSS Feed</h4>
        <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
          <i class="fal fa-times"></i>
        </router-link>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <button @click="$router.push({name: 'select_content'})"
                  class="btn---cta light-blue btn-round">
            <span>Go Back</span>
          </button>
          <div class="circles">
            <span class="dotted_circle active"></span>
          </div>
          <button class="btn---cta btn-blue btn-round block-btn" @click="validateAndStoreRSSBlock()"
                  :disabled="isAddingBlock || preview_loader || validations.rss_data_required">
                            <span>
                                <template v-if="getBioLinkRssSectionAdd._id">
                                    Update Block
                                    <clip-loader v-if="isAddingBlock" class="inline-spinner" :size="'14px'"
                                                 :color="'#ffffff'"></clip-loader>
                                </template>
                                <template v-else>
                                    Save Block
                                    <clip-loader v-if="isAddingBlock" class="inline-spinner" :size="'14px'"
                                                 :color="'#ffffff'"></clip-loader>
                                </template>

                            </span>
          </button>
        </div>

        <div class="input-block-list">
          <div class="block-heading">
            <p>Display your latest blog posts in a vertical grid. Each post is shown with the title, image,
              and a basic description. You can display up to 5 posts at one time!</p>
          </div>

          <div class="item-top-area d-flex align-items-center">
            <div class="item-right ml-auto">
              <div class="switch-toggle">
                <span>Show Title</span>
                <label class="switch" for="show_titlee">
                  <input v-model="getBioLinkRssSectionAdd.show_title" type="checkbox"
                         id="show_titlee">
                  <div class="slider round"></div>
                </label>
              </div>
            </div>
          </div>

          <div class="mt-3 input_icon_text d-flex align-items-center  "
               :class="{'input-field-error': validations.title_required || validations.title_length}">

            <div class="content">
              <div class="url">Add A Title</div>
              <div class="name">
                <div class="validation_input">
                  <input v-model="getBioLinkRssSectionAdd.title" class="" type="text"
                         :disabled="!getBioLinkRssSectionAdd.show_title"
                         placeholder="Add A Title..." data-cy="rss-block-title">
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.title_required">{{ messages.title_required }}</span>
          <span class="input-error" v-else-if="validations.title_length">{{ messages.title_length }}</span>
          <div class="add-block-list">
            <div class="add-block-item">
              <div class="block-inner">
                <div class="  input_icon_text d-flex align-items-center  mt-3"
                     :class="{'input-field-error':validations.url || validations.urlValid || validations.urlLength}">
                  <div class="avatar">
                    <i style="font-size: 24px;" class="far fa-rss"></i>
                  </div>
                  <div class="content">
                    <div class="url">Your RSS feed URL</div>
                    <div class="name">
                      <div class="validation_input">
                        <url
                          :value="getBioLinkRssSectionAdd.rss"
                          @onEnter="validateAndStoreRSSBlock($event)"
                          @input="debounceRssFeed"
                          :validations="validations"
                          v-bind:blur="() => getBioLinkRssSectionAdd.rss = focusoutConcatenation(getBioLinkRssSectionAdd.rss)"
                          ref="urlRef"
                          data-cy="rss-url"
                          placeholder="Your RSS Feed URL e.g https://blog.mywebsite.com/feed"></url>
                      </div>
                    </div>
                  </div>
                  <div class="avatar mr-0 ml-3" v-if="preview_loader">
                    <clip-loader :color="'#168eea'" :size="'14px'"></clip-loader>
                  </div>
                </div>
                <span class="input-error" v-if="validations.url">{{ messages.url }}</span>
                <span class="input-error" v-else-if="validations.urlLength">{{ messages.urlLength }}</span>
                <span class="input-error" v-else-if="validations.urlValid">{{ messages.urlValid }}</span>

                <card-number-component
                  @cardNumberObj="cardNumberObj($event)"
                  :card_number="getBioLinkRssSectionAdd.card_number"
                ></card-number-component>

                <!-- Style component -->
                <style-component
                  @styleObj="styleObj($event)"
                  :styling="getBioLinkRssSectionAdd.style"
                ></style-component>

                <!-- Animation component-->
                <animation-component
                  @animationObj="animationObj($event)"
                  :animation_enabled="getBioLinkRssSectionAdd.animation_enabled"
                  :animation="getBioLinkRssSectionAdd.animation"
                ></animation-component>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'
import BioShowTitleSnippet from './short/BioShowTitleSnippet'
import {bioLinkTypes} from '@/state/modules/mutation-types'
import URL from '@/ui/URL'
import {bioLinksRssFeedValidations, bioLinksRssFeedMessages} from '../../../../../common/attributes'

export default ({
  data () {
    return {
      validations: bioLinksRssFeedValidations,
      messages: bioLinksRssFeedMessages,
      preview_loader: false,
      isFeedUrlClicked: false,
      color: DARK_PURPLE_LOADER_COLOR,
      isAddingBlock: false,
      rssValue: '',
      isSubmit: false,
      debounce: null,
      tempPosts: []
    }
  },
  components: {
    'style-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/StyleComponent'),
    'animation-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/AnimationComponent'),
    'card-number-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/CardNumberComponent'),
    BioShowTitleSnippet,
    'url': URL
  },
  created () {
    this.isBioLinkFirstStepVerified()
  },
  computed: {
    ...mapGetters([
      'getBioLinkRssSectionAdd'
    ])
  },
  mounted () {
    this.rssValue = this.getBioLinkRssSectionAdd.rss
    if(this.getBioLinkRssSectionAdd && !this.getBioLinkRssSectionAdd.card_number) {
      this.getBioLinkRssSectionAdd.card_number = 5
    }
    this.tempPosts = JSON.parse(JSON.stringify(this.getBioLinkRssSectionAdd.posts))
  },
  methods: {
    ...mapActions([
      'getRssFeedBioLink'
    ]),
    async validateAndStoreRSSBlock () {
      if (this.getBioLinkRssSectionAdd.show_title) {
        this.validations.title_required = this.requiredCheck(this.getBioLinkRssSectionAdd.title)
      } else {
        this.validations.title_required = false
      }
      this.validations.url = !this.urlCheck(this.getBioLinkRssSectionAdd.rss)

      if (!this.validations.url && !this.validations.title_required && this.validations.urlValid) {
        this.isSubmit = true
        this.rssFeedPreviewValidation(this.getBioLinkRssSectionAdd.rss)
      }

      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      // if the value validated and title required
      if (result) {
        this.isAddingBlock = true
        let res = await this.$store.dispatch('storeBioContentSection', 'getBioLinkRssSectionAdd')
        if (res && res.data.status) {
          this.isAddingBlock = false
          this.$router.push({name: 'select_content'})
        } else {
          this.alertMessage(res.data.message, 'error')
        }

      }
    },
    debounceRssFeed (event) {
      this.getBioLinkRssSectionAdd.rss = event
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        this.rssFeedPreviewValidation()
      }, 600)
    },
    // async rssFeedPreviewValidation (isSubmit = false) {
    async rssFeedPreviewValidation (event = null) {
      this.preview_loader = true
      this.validations.rss_data_required = true
      let url = this.getBioLinkRssSectionAdd.rss
      if (this.isValidURL(url)) {
        let res = await this.getRssFeedBioLink(url)
        if(res.status && res.data && res.data.length) {

          res.data.forEach(item => {
            item.title = item.title.split('\n').join(' ')
          })

          this.tempPosts = res.data
          this.$store.commit(bioLinkTypes.SET_RSS_CONTENT_SECTION_POSTS, {posts: [], card_number: this.getBioLinkRssSectionAdd.card_number})
          this.$store.commit(bioLinkTypes.SET_RSS_CONTENT_SECTION_POSTS, {posts: res.data, card_number: this.getBioLinkRssSectionAdd.card_number})
          this.validations.rss_data_required = false
          this.preview_loader = false
          return
        }

        if(!(!res.data && !res.data.length)) {
          this.validations.rss_data_required = true
          this.preview_loader = false
          this.$store.dispatch('toastNotification', {message: 'Unknown error occurred. Please try again!', type: 'error'})
          return
        }

        this.$store.dispatch('toastNotification', {message: res.message, type: 'error'})
        this.validations.rss_data_required = true
        this.preview_loader = false
        return
      }
    },
    styleObj (event) {
      this.getBioLinkRssSectionAdd.style = event.style
    },
    animationObj (event) {
      this.getBioLinkRssSectionAdd.animation_enabled = event.animation_enabled
      this.getBioLinkRssSectionAdd.animation = event.animation
    },
    cardNumberObj(event) {
      this.getBioLinkRssSectionAdd.card_number = event.card_number
      this.getBioLinkRssSectionAdd.posts = JSON.parse(JSON.stringify(this.tempPosts.slice(0, event.card_number)))
    }
  },
  watch: {
    'getBioLinkRssSectionAdd.title' (value) {
      if (value && value.length > 0) {
        this.validations.title_required = false
        this.validations.title_length = !this.maxLength(value, 100)
      }
    }
  }
})
</script>

<style scoped>
.bio_link_layout .bio_link_main_view .view_inner .view_center .steps_dot .circles {
  width: 495px;
  text-align: center;
  display: inline-block;
}

.input_icon_text .avatar {
  margin-top: 10px;
}
</style>
