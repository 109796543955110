import { render, staticRenderFns } from "./BioShowTitleSnippet.vue?vue&type=template&id=2da49a5a"
import script from "./BioShowTitleSnippet.vue?vue&type=script&lang=js"
export * from "./BioShowTitleSnippet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports